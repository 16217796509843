import 'slick-carousel';

jQuery(async function () {
	$('.home .slick-carousel').slick({
		dots: true,
		arrows: true,
		infinite: false,
		autoplay: false,
		speed: 300,
		slidesToShow: 5,
		touchThreshold: 1000,
		slidesToScroll: 5,
		lazyLoad: 'ondemand',
		responsive: [
			{
				breakpoint: 1399,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			},
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}
		]
	});
});